





























































import Vue from 'vue';
import Paper from "@/components/Paper.vue";
import {PrintSymbol} from "@/type/PrintSymbol";
import {mapState} from "vuex";

export default Vue.extend({
  name: 'Home',
  components: {Paper},
  data() {
    return {
      symbolList: [] as Array<PrintSymbol>,
      new_char: new PrintSymbol,
      amount: 1,
      runningNumber: {
        placeholder: "",
        amount: 1
      }
    }
  },
  computed: {
    ...mapState(['layout'])
  },
  mounted() {},
  methods: {
    addCharacter() {
      for (let i = 0; i < this.amount; i++) {
        this.symbolList.push(JSON.parse(JSON.stringify(this.new_char)));
      }
    },
    addCharacterNumber() {
      const [min, max] = this.runningNumber.placeholder.split("-");
      for (let i = parseInt(min); i <= parseInt(max); i++) {
        for (let j = 0; j < this.runningNumber.amount; j++) {
          this.symbolList.push(new PrintSymbol().setSymbol(`${i}`));
        }
      }
    },
    printPaper() {
      window.print();
    }
  }
});
