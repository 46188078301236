












import Vue from 'vue';
import {PrintSymbol} from "@/type/PrintSymbol";
import {mapState} from "vuex";

export default Vue.extend({
  name: 'Paper',
  props: ['value'],
  data() {
    return {
      skipFirstPage: true
    }
  },
  computed: {
    ...mapState(['layout']),
    printSymbolList(): Array<PrintSymbol> {
      return this.value;
    }
  },
  methods: {
    doSpacing(index: number): boolean {
      return (index % 35) === 30;
    }
  },
  watch: {
    value: function (val) {
      this.$emit('value', this.value);
      console.log(this.value)
    }
  }
});
